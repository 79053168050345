import { Modal, ModalProps, Stack, styled } from '@mui/material';
import { SystemProps } from '@mui/system';

interface StandardModalProps extends Omit<ModalProps, 'children'> {
  width?: SystemProps['width'];
  children: React.ReactNode;
}
const StandardModal: React.FC<StandardModalProps> = props => {
  const { children, width = '40%', ...rest } = props;
  return (
    <Modal {...rest}>
      <ModalBox width={width}>{children}</ModalBox>
    </Modal>
  );
};
export default StandardModal;

export const ModalBox = styled(Stack)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1000,
  minWidth: '600px',
  background: 'white',
  padding: 20,
  borderRadius: 5,
  maxHeight: '80%',
  overflow: 'auto',
});
