import { Box, Stack, Typography } from '@mui/material';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import StandardModal from '../../components/Modal';
import AllUsers from './All';
import { UsersMgrProvider } from './context';
import CreateUser from './Create';
import EditUser from './Edit';

const UsersManager: React.FC = () => {
  const navigate = useNavigate();
  return (
    <UsersMgrProvider>
      <Routes>
        <Route
          path='*'
          element={
            <Stack spacing={2} height='100%'>
              <Typography variant='h4'>Users Manager</Typography>
              <Box flexGrow={1} minHeight={0}>
                <AllUsers />
              </Box>
              <Outlet />
            </Stack>
          }
        >
          <Route
            path='create'
            element={
              <StandardModal open={true} onClose={() => navigate('.')}>
                <CreateUser onClose={() => navigate('.')} />
              </StandardModal>
            }
          />
          <Route
            path='edit/:id'
            element={
              <StandardModal open={true} onClose={() => navigate('.')}>
                <EditUser onClose={() => navigate('.')} />
              </StandardModal>
            }
          />
          <Route path='*' element={<Navigate to='.' />} />
        </Route>
      </Routes>
    </UsersMgrProvider>
  );
};
export default UsersManager;
