import * as Icons from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { useMemo, useState } from 'react';
import StandardModal from '../../components/Modal';

export type IconName = keyof typeof Icons;

interface IconPickerProps {
  open: boolean;
  onClose: () => void;
  onSelectIcon: (iconName: IconName) => void;
}

const IconPicker: React.FC<IconPickerProps> = ({
  open,
  onClose,
  onSelectIcon,
}) => {
  const [iconFilter, setIconFilter] = useState('');

  const filteredIcons = useMemo(() => {
    return Object.keys(Icons)
      .filter(iconName =>
        iconName.toLowerCase().includes(iconFilter.toLowerCase()),
      )
      .slice(0, 100);
  }, [iconFilter]);

  return (
    <StandardModal open={open} onClose={onClose}>
      <Typography variant='h6' component='h2' gutterBottom>
        Select an Icon
      </Typography>
      <TextField
        label='Filter icons'
        variant='outlined'
        fullWidth
        value={iconFilter}
        onChange={e => setIconFilter(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {filteredIcons.map(iconName => {
          const IconComponent = Icons[
            iconName as IconName
          ] as React.ComponentType<SvgIconProps>;
          return (
            <IconButton
              key={iconName}
              onClick={() => onSelectIcon(iconName as IconName)}
              color='warning'
              style={{ color: 'black !important' }}
            >
              <IconComponent />
            </IconButton>
          );
        })}
      </Box>
      {filteredIcons.length === 100 && (
        <Typography variant='body2' sx={{ mt: 2 }}>
          Showing first 100 results. Refine your search to see more.
        </Typography>
      )}
    </StandardModal>
  );
};

export const DynamicIcon: React.FC<{
  iconName: string;
  style?: React.CSSProperties;
}> = ({ iconName, style }) => {
  const IconComponent = (Icons as any)[iconName];
  return IconComponent ? <IconComponent style={style} /> : null;
};

export default IconPicker;
