import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IInflightMetadata, Platform } from '../models';

const TikTokIcon = () => {
  return (
    <svg
      fill='#000000'
      width='24px'
      viewBox='0 0 512 512'
      id='icons'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z' />
    </svg>
  );
};

interface VidCardProps {
  id: string;
  metadata: IInflightMetadata;
  onClick?: (id: string) => void;
  duration?: number;
}

const VidCard: React.FC<VidCardProps> = ({
  metadata,
  id,
  onClick,
  duration,
}) => {
  const navigate = useNavigate();

  const { platform, brand, name, country, campaign, thumbnailUrl } = metadata;

  const getPlatformIcon = (platform: Platform) => {
    switch (platform) {
      case 'YouTube':
        return <YouTube />;
      case 'Facebook':
        return <Facebook />;
      case 'Instagram':
        return <Instagram />;
      case 'Twitter':
        return <Twitter />;
      case 'TikTok':
        return <TikTokIcon />;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onClick) {
      onClick(id);
    } else {
      navigate(`/videos/${id}`);
    }
  };

  function formatVideoDuration(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds) % 60;

    // Format minutes and seconds with leading zeros if needed
    const formattedMinutes =
      hours > 0 && minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: '1px 20px 40px 0 #0000000f',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '1px 10px 20px 0 #0000000f',
          background:
            'linear-gradient(25deg, #EDEFFD 0%, #F6F5EE 36%, #F7F5ED 50%)',
        },
      }}
    >
      <CardActionArea onClick={handleClick} sx={{ flexGrow: 1 }}>
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            component='img'
            height='140'
            image={thumbnailUrl}
            alt={`${brand} - ${campaign}`}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 5,
              right: 5,
              bgcolor: 'rgba(0, 0, 0, 0.6)',
              padding: '1px 4px',
              display: 'flex',
              color: 'white',
              borderRadius: '5px',
              justifyContent: 'space-between',
            }}
          >
            <Typography fontSize={12}>
              {duration && formatVideoDuration(duration)}
            </Typography>
          </Box>
        </Box>
        <CardContent>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mb={0}
          >
            <Typography
              variant='subtitle1'
              component='div'
              noWrap
              fontWeight={600}
              title={name}
            >
              {name}
            </Typography>
            {getPlatformIcon(platform)}
          </Box>
          <Typography fontSize={13} noWrap mb={4}>
            {campaign}
          </Typography>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mt={2}
            sx={{ textTransform: 'capitalize' }}
          >
            <Chip
              avatar={
                <Avatar
                  style={{
                    padding: '2px',
                    backgroundColor: '#000',
                    color: '#fff',
                  }}
                >
                  {country.slice(0, 2).toUpperCase()}
                </Avatar>
              }
              label={country}
              variant='outlined'
              style={{ color: 'black', border: 'none' }}
              size='small'
            />
            <Typography variant='caption' noWrap>
              {brand}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default VidCard;
