import { Box, Stack } from '@mui/material';
import React from 'react';
import FiltersBar from '../FiltersBar';
import { FiltersContextProvider } from '../FiltersBar/context';
import { LibraryProvider } from './context';
import DisplayMode from './DisplayMode';
import PaginationBar from './PaginationBar';
import Results from './Results';

const Library: React.FC = () => {
  return (
    <FiltersContextProvider type='inflight'>
      <LibraryProvider>
        <LibraryInner />
      </LibraryProvider>
    </FiltersContextProvider>
  );
};
export default Library;

const LibraryInner: React.FC = () => {
  return (
    <Stack height='100%'>
      <FiltersBar>
        <DisplayMode />
      </FiltersBar>
      <PaginationBar />
      <Box flexGrow={1} minHeight={0} overflow='auto'>
        <Results />
      </Box>
    </Stack>
  );
};
