import styled from '@emotion/styled';
import { Box, Button, Grid, Stack } from '@mui/material';
import { ApiError } from '../services/api';
import ApiErrorComponent from './ApiErrorComponent';

export interface FormSkeletonProps {
  onSubmit: (e: React.FormEvent<any>) => void;
  onClose: () => void;
  valid: boolean;
  loading?: boolean;
  error?: ApiError | null;
  children: React.ReactNode;
}
const FormSkeleton: React.FC<FormSkeletonProps> = props => {
  const { onSubmit, onClose, valid, loading, error, children } = props;
  return (
    <Stack spacing={2} component='form' onSubmit={onSubmit} alignItems='center'>
      {children}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormButton
            variant='contained'
            color='secondary'
            sx={{
              color: 'black',
              bgcolor: 'white',
            }}
            fullWidth
            onClick={onClose}
          >
            Cancel
          </FormButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormButton
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              color: 'white',
              bgcolor: 'black',
            }}
            disabled={!valid || loading}
            fullWidth
          >
            Submit
          </FormButton>
        </Grid>
      </Grid>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
      ></Box>
      <ApiErrorComponent error={error} />
    </Stack>
  );
};
export default FormSkeleton;

export const FormButton = styled(Button)({
  px: 2,
  borderRadius: '10px',
  textTransform: 'none',
});
