import { Analytics } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import FiltersBar from '../../FiltersBar';
import { FiltersContextProvider } from '../../FiltersBar/context';
import InsightCards from '../Cards';
import { InsightProvider, useInsight } from './context';
import InsightSpotterDisclaimer from './Disclaimer';

const InsightSpotter: React.FC = () => {
  return (
    <FiltersContextProvider type='inflight' analyzed={true}>
      <InsightProvider>
        <Stack height='100%'>
          <InsightSpotterDisclaimer />
          <FiltersBar>
            <AnalyzeButton />
          </FiltersBar>
          <InsightsSpotterResults />
        </Stack>
      </InsightProvider>
    </FiltersContextProvider>
  );
};
export default InsightSpotter;

const InsightsSpotterResults: React.FC = () => {
  const { insights, setInsights } = useInsight();
  return (
    <Box flex={1} overflow='auto' p={2}>
      {insights.length === 0 ? (
        <Box textAlign='center'>
          <Typography variant='body1' color={'text.secondary'} gutterBottom>
            Use the filter and click on "Analyze" to calculate the insights
          </Typography>
        </Box>
      ) : (
        <InsightCards insights={insights} setInsights={setInsights} />
      )}
    </Box>
  );
};

const AnalyzeButton: React.FC = () => {
  const { calculateInsights } = useInsight();
  return (
    <Button
      variant='contained'
      style={{
        backgroundColor: '#000',
        width: '200px',
        color: '#fff',
        borderRadius: '20px',
        height: '40px',
      }}
      startIcon={<Analytics />}
      onClick={calculateInsights}
    >
      Analyze
    </Button>
  );
};
