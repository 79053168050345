import React, { createContext, useCallback, useContext, useState } from 'react';
import { useAuthenticatedUser } from '../../../contexts/auth';
import IInsight from '../../../models/insights';
import useFilters, { Filters } from '../../FiltersBar/context';

interface InsightContextType {
  filters: Filters;
  insights: IInsight[];
  setInsights: React.Dispatch<React.SetStateAction<IInsight[]>>;
  loading: boolean;
  calculateInsights: () => void;
}

const InsightContext = createContext<InsightContextType | null>(null);

export const InsightProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { api } = useAuthenticatedUser();
  const { filters } = useFilters();
  const [insights, setInsights] = useState<IInsight[]>([]);
  const [loading, setLoading] = useState(false);

  const calculateInsights = useCallback(() => {
    const { type, ...rest } = filters;
    api.insights
      .getInsights(rest)
      .then(setInsights)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [api, filters]);

  const validInsights = insights.filter(insight => {
    const [factorValue] = insight.content.match(/\d+(\.\d+)?/) || [];
    return factorValue && parseFloat(factorValue) > 0;
  });

  const value: InsightContextType = {
    filters,
    insights: validInsights,
    setInsights,
    loading,
    calculateInsights,
  };

  return (
    <InsightContext.Provider value={value}>{children}</InsightContext.Provider>
  );
};

export const useInsight = () => {
  const context = useContext(InsightContext);
  if (!context) {
    throw new Error('useInsights must be used within an InsightProvider');
  }
  return context;
};
