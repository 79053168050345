import {
  BrandingWatermark,
  Face3Outlined,
  Help,
  MusicNote,
  RecordVoiceOverOutlined,
  SellOutlined,
  SentimentSatisfiedAlt,
  SubjectOutlined,
} from '@mui/icons-material';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { IMomentResult, Occurence, VideoResult } from '../../models/moments';

interface VideoDetailsProps {
  result: IMomentResult;
}

const styles = `
  .Occurence-container {
    background: #f3f4f6;
    border-radius: 10px;
    padding: 10px;
  }
`;

export const getIconForType = (type: string): React.ReactElement => {
  const iconStyle: SxProps<Theme> = { fontSize: 16 };
  switch (type.toLowerCase()) {
    case 'people':
      return <Face3Outlined sx={iconStyle} />;
    case 'products':
    case 'product':
      return <SellOutlined sx={iconStyle} />;
    case 'logos':
      return <BrandingWatermark sx={iconStyle} />;
    case 'text':
      return <SubjectOutlined sx={iconStyle} />;
    case 'speech':
      return <RecordVoiceOverOutlined sx={iconStyle} />;
    case 'emotion':
      return <SentimentSatisfiedAlt sx={iconStyle} />;
    case 'music':
      return <MusicNote sx={iconStyle} />;
    default:
      return <Help sx={iconStyle} />;
  }
};

const VideoDetails: React.FC<VideoDetailsProps> = ({ result }) => {
  const { video } = result;
  const { metadata } = video;

  const renderOccurrences = (results: VideoResult[]): React.ReactNode => {
    const groupedOccurrences = results.reduce<Record<string, Occurence[]>>(
      (acc, result) => {
        if (!acc[result.type]) {
          acc[result.type] = [];
        }
        const filteredItems = result.items.filter(
          item => item.appearances.length > 0,
        );
        acc[result.type] = [...acc[result.type], ...filteredItems];
        return acc;
      },
      {},
    );

    const GroupedItemList = ({
      items,
      type,
    }: {
      items: Occurence[];
      type: string;
    }) => {
      // Group items by content
      const groupedItems = items.reduce(
        (acc, item) => {
          if (!acc[item.content]) {
            acc[item.content] = { ...item, allMatches: [item.matches] };
          } else {
            acc[item.content].allMatches.push(item.matches);
          }
          return acc;
        },
        {} as Record<string, Occurence & { allMatches: string[] }>,
      );

      return (
        <Box key={type} sx={{ color: '#000', mb: 3 }}>
          <Typography
            variant='subtitle1'
            sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
          >
            {getIconForType(type)}
            <Box component='span' sx={{ ml: 1 }}>
              {items[0].uiMessage}
            </Box>
          </Typography>
          {Object.values(groupedItems).map((item, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}
            >
              {type.toLowerCase() === 'people' && item.image && (
                <Box
                  component='img'
                  src={item.image}
                  sx={{ width: 80, height: 80, borderRadius: '10%', mr: 2 }}
                  alt={item.content}
                />
              )}
              <Box>
                <Typography
                  variant='body1'
                  sx={{
                    fontWeight: 'medium',
                    ml: 1,
                    borderRadius: '10%',
                    mr: 2,
                    fontSize: '12px',
                  }}
                >
                  {item.content}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: '10px' }}>
                  {item.allMatches.map((match, matchIndex) => (
                    <Box
                      key={matchIndex}
                      sx={{
                        bgcolor: '#E96470',
                        color: '#fff',
                        borderRadius: '10px',
                        p: 1,
                        fontSize: '10px',
                        ml: 1,
                        mb: 1,
                      }}
                    >
                      {match}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      );
    };

    return Object.entries(groupedOccurrences).map(([type, items]) => (
      <GroupedItemList key={type} items={items} type={type} />
    ));
  };

  return (
    <>
      <style>{styles}</style>
      <Box ml={2} sx={{ color: '#fff' }}>
        <Box sx={{ borderRadius: 2, bgcolor: '#231F60', p: 2, mb: 2 }}>
          <Typography variant='h5' sx={{ mb: 2 }}>
            {metadata.name}
          </Typography>
        </Box>

        <div className='Occurence-container'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography sx={{ mb: 2, color: '#000', fontSize: 18 }}>
              Occurrences
            </Typography>
            <Typography
              sx={{
                mb: 2,
                p: 1,
                pt: 0.5,
                pb: 0.5,
                fontSize: 10,
                bgcolor: '#E96470',
                color: '#fff',
                borderRadius: 10,
              }}
            >
              {result.results.length}
            </Typography>
          </Box>
          {renderOccurrences(result.results)}
        </div>
      </Box>
    </>
  );
};

export default VideoDetails;
