import { GridView, ViewList } from '@mui/icons-material';
import { Stack, IconButton } from '@mui/material';
import useLibrary from './context';

const DisplayMode: React.FC = () => {
  const { displayMode, setDisplayMode } = useLibrary();
  return (
    <Stack direction='row' spacing={1}>
      <IconButton
        onClick={() => setDisplayMode('grid')}
        color={displayMode === 'grid' ? 'primary' : 'default'}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
          },
        }}
      >
        <GridView />
      </IconButton>
      <IconButton
        onClick={() => setDisplayMode('list')}
        color={displayMode === 'list' ? 'primary' : 'default'}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
          },
        }}
      >
        <ViewList />
      </IconButton>
    </Stack>
  );
};
export default DisplayMode;
