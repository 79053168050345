import { Button, Stack, Typography } from '@mui/material';
import Papa, { ParseResult } from 'papaparse';
import { useCallback, useEffect, useState } from 'react';
import FileDropzone from '../../components/FileDropzone';
import FormSkeleton from '../../components/Form';
import NormalizedSelect from '../../components/NormalizedSelect';
import { useAuthenticatedUser } from '../../contexts/auth';
import { IPreflightVideo, isPerfKey, Source, sources } from '../../models';
import { ApiError } from '../../services/api';
import parseCsv from '../../utils/csv';

const internalHeaders = [
  'name',
  'platform',
  'brand',
  'country',
  'campaign',
  'objectives',
  'downloadUrl',
] as const;
const headers = {
  internal: internalHeaders,
  opera: ['assetId', 'objectives', 'country'],
  bic: ['assetId', ...internalHeaders],
} as const;

type Row<T extends Source = Source> = Record<
  (typeof headers)[T][number],
  string
>;

const BulkCreatePreflight: React.FC<{
  postCreate: (...preflights: IPreflightVideo[]) => void;
  onClose: () => void;
}> = ({ postCreate, onClose }) => {
  const { api } = useAuthenticatedUser();
  const [file, setFile] = useState<File | null>(null);
  const [source, setSource] = useState<Source>('internal');
  const [parsedRes, setParsedRes] = useState<ParseResult<Row> | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      api.preflight
        .createBulk(file!, source)
        .then(preflights => postCreate(...preflights))
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [api, file, source, postCreate],
  );
  const valid = !!file && !parsedRes?.errors.length;

  const onFileSelect = (file: File) => setFile(file);

  useEffect(() => {
    if (!file) return;
    parseCsv(file, {
      header: true,
      skipEmptyLines: true,
      requiredHeaders: [...headers[source]],
      validateRow: row => {
        return row.objectives
          .split(',')
          .map(key => !isPerfKey(key) && `Invalid objective '${key}'`)
          .filter(Boolean)
          ?.join(', ');
      },
      complete: setParsedRes,
    });
  }, [file, source]);

  return (
    <FormSkeleton
      onSubmit={handleSubmit}
      onClose={onClose}
      valid={!!valid}
      loading={loading}
      error={error}
    >
      <Button
        variant='contained'
        color='primary'
        fullWidth
        component='a'
        href={urls[source]}
        download={`preflight-extract-${source}.csv`}
      >
        Download example CSV
      </Button>
      <FileDropzone
        onFileSelect={onFileSelect}
        accept={['text/csv']}
        file={file}
        onError={() => setFile(null)}
        height={150}
        maxFileSize={50 * 1024 * 1024} // 50 MB
      />
      <Stack direction='row' alignItems='center' spacing={2} width='100%'>
        <NormalizedSelect
          label='Source'
          value={source}
          onChange={setSource}
          options={sources}
          variant='outlined'
          noAll
        />
        <Typography color='warning.main' variant='body2'>
          ⚠️ CSV format depends on source
        </Typography>
      </Stack>
      {parsedRes && <FilePreview parsedRes={parsedRes} />}
    </FormSkeleton>
  );
};
export default BulkCreatePreflight;

const FilePreview: React.FC<{ parsedRes: ParseResult<Row> }> = ({
  parsedRes,
}) => {
  const { data, errors, meta } = parsedRes;
  return (
    <Stack spacing={2} width='100%'>
      <Typography variant='h6'>Preview</Typography>
      <Typography variant='body1'>
        {data.length} rows and {meta.fields?.length} columns
      </Typography>
      {errors.length > 0 && (
        <Stack spacing={1}>
          <Typography variant='h6'>Errors</Typography>
          {errors.map((e, i) => (
            <Typography key={i} variant='body1' color='error'>
              {e.row !== undefined && `Row ${e.row + 1}: `}
              {e.message}
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const exampleRows: { [K in Source]: Row<K> } = {
  internal: {
    name: 'Testing preflight',
    platform: 'YouTube',
    brand: 'Lancôme',
    country: 'France',
    campaign: 'idole_new_fragrance',
    objectives: 'CTR,ConversionRate',
    downloadUrl:
      'https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4',
  },
  opera: {
    assetId: '123456',
    objectives: 'CTR,ConversionRate',
    country: 'France',
  },
  bic: {
    assetId: '123456',
    name: 'Testing preflight',
    platform: 'YouTube',
    brand: 'Lancôme',
    country: 'France',
    campaign: 'idole_new_fragrance',
    objectives: 'CTR,ConversionRate',
    downloadUrl:
      'https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4',
  },
};

function getCsvUrl(source: Source) {
  const csvData = [exampleRows[source]];
  const csvContent = Papa.unparse(csvData, { delimiter: ';' });
  const csvBlob = new Blob([csvContent], { type: 'text/csv' });
  return URL.createObjectURL(csvBlob);
}
const urls: Record<Source, string> = {
  internal: getCsvUrl('internal'),
  opera: getCsvUrl('opera'),
  bic: getCsvUrl('bic'),
};
