import { Box, TextField } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { ManagedState } from '../utils/typing';
import NormalizedSelect from './NormalizedSelect';

const presets = ['last3Months', 'last6Months', 'lastYear'] as const;
type CustomDateRange = `${string}~${string}`;
const DEFAULT: PresetDateRange = 'last3Months';

type PresetDateRangePickerProps = ManagedState<
  'dateRange',
  PresetDateRange | undefined
> & {
  defaultValue?: PresetDateRange;
};
/**
 * A date filter with presets for common date ranges.
 * Usage:
 * ```tsx
 * const [dateRange, setDateRange] = useState('last3Months');
 * useEffect(() => {
 *  if (!dateRange) return;
 *  const { start, end } = parsePresetDateRange(dateRange);
 *  console.log({ start, end });
 * }, [dateRange]);
 * return (
 *  <PresetDateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
 * );
 * ```
 */
const PresetDateRangePicker: React.FC<PresetDateRangePickerProps> = props => {
  const { dateRange, setDateRange, defaultValue = DEFAULT } = props;

  const { preset, start, end } = useMemo(() => {
    const r = dateRange || defaultValue;
    return {
      preset: getPreset(r),
      ...parsePresetDateRange(r),
    };
  }, [dateRange, defaultValue]);

  const setPreset = useCallback(
    (newPreset: Preset | 'custom') => {
      if (newPreset === 'custom')
        setDateRange(prev => {
          const ref = prev || defaultValue;
          if (isDateRangePreset(ref))
            return serializeCustom(parsePresetDateRange(ref));
          return ref;
        });
      else setDateRange(newPreset);
    },
    [setDateRange, defaultValue],
  );

  const setCustomDateRange = useCallback(
    (start: Date, end: Date) => setDateRange(serializeCustom({ start, end })),
    [setDateRange],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <NormalizedSelect
        label='Date Range Preset'
        value={preset}
        onChange={setPreset}
        options={[...presets, 'custom']}
        formatOption={formatPreset}
        noAll
      />
      {preset === 'custom' && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label='Start Date'
            type='date'
            variant='standard'
            value={start ? start.toISOString().slice(0, 10) : ''}
            onChange={e => setCustomDateRange(new Date(e.target.value), end)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='End Date'
            type='date'
            variant='standard'
            value={end ? end.toISOString().slice(0, 10) : ''}
            onChange={e => setCustomDateRange(start, new Date(e.target.value))}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      )}
    </Box>
  );
};

export default PresetDateRangePicker;

type Preset = (typeof presets)[number];
function isDateRangePreset(value: string): value is Preset {
  return presets.includes(value as Preset);
}
export type PresetDateRange = Preset | CustomDateRange;

type DateRange = { start: Date; end: Date };

function serializeCustom(range: DateRange): CustomDateRange {
  const format = (date: Date) => date.toISOString().slice(0, 10); // YYYY-MM-DD
  return `${format(range.start)}~${format(range.end)}`;
}

export function parsePresetDateRange(value: PresetDateRange) {
  if (isDateRangePreset(value)) {
    const now = new Date();
    switch (value) {
      case 'last3Months':
        return {
          start: new Date(now.getFullYear(), now.getMonth() - 3, now.getDate()),
          end: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        };
      case 'last6Months':
        return {
          start: new Date(now.getFullYear(), now.getMonth() - 6, now.getDate()),
          end: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        };
      case 'lastYear':
        return {
          start: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
          end: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        };
    }
  }
  const [startStr, endStr] = value.split('~');
  const start = new Date(startStr);
  const end = new Date(endStr);
  return { start, end };
}

function getPreset(range: PresetDateRange) {
  return isDateRangePreset(range) ? range : ('custom' as const);
}

function formatPreset(preset: Preset | 'custom') {
  switch (preset) {
    case 'last3Months':
      return 'Last 3 Months';
    case 'last6Months':
      return 'Last 6 Months';
    case 'lastYear':
      return 'Last Year';
    case 'custom':
      return 'Custom';
  }
}
